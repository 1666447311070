<template>
	<v-container
		id="Group_Configs"
		fluid
		tag="section">
		<v-row dense>
			<v-col>
				<v-card class="v-card--material pa-3">
					<card-heading
						:color="'secondary'"
						:title="'Group configs'">
						<v-btn
							color="primary"
							elevation="1"
							fab
							small
							@click="onAddClick"
							v-if="
								_usrFlagsSome({
									key: 'bonus.group_configs.detail',
									val: permissions.CREATE,
								})
							">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</card-heading>
					<v-data-table
						:headers="filteredHeaders"
						:items="list"
						:options.sync="options"
						:server-items-length="total_records"
						:item-class="itemClass"
						:loading="loading"
						:footer-props="footerProps"
						class="elevation-1">
						<template #top="{ pagination }">
							<v-data-footer
								class="anton"
								:options.sync="options"
								v-bind="footerProps"
								:pagination="pagination"></v-data-footer>
						</template>
						<template #[`item.id`]="{ item }">
							{{ item.id }}
							<v-chip
								class="ml-3"
								v-if="
									item.deleted &&
									_usrFlagsSome({
										key: 'bonus.group_configs.list',
										val: permissions.DELETE,
									})
								"
								small
								color="error">
								Deleted
							</v-chip>
						</template>
						<template #[`item.contrib_type_id`]="{ item }">
							{{
								item.contrib_type_id === 1
									? CONTRIB_TYPES.PERCENT
									: CONTRIB_TYPES.BET | Capitalize
							}}
						</template>
						<template v-slot:[`item.deleted`]="{ item }">
							{{ item.deleted | Date }}
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<v-tooltip
								color="primary darken-2"
								bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-bind="attrs"
										v-on="on"
										nudge-bottom="1"
										v-if="
											_usrFlagsSome({
												key: 'bonus.group_configs.detail',
												val: permissions.EDIT,
											})
										"
										color="primary"
										size="22"
										class="mx-1"
										@click="onEditItem(item)">
										mdi-pencil
									</v-icon>
								</template>
								<span>Edit</span>
							</v-tooltip>

							<v-tooltip
								color="primary darken-2"
								bottom
								:key="`${item.id}-${item.deleted}-deleteBtn`">
								<template v-slot:activator="{ on, attrs }">
									<ConfirmDialog2
										persistent
										:confirmBtnText="'Delete'"
										:cancelBtnText="'Back'"
										:confirmBtnColor="'error'"
										:titleClass="'error'"
										:cancelBtnColor="'kajot-text'"
										:shouldShow="true"
										v-if="
											item.deleted == null &&
											_usrFlagsSome({
												key: 'bonus.group_configs.detail',
												val: permissions.DELETE,
											})
										"
										@confirm="onDeleteItem(item.id)">
										<template v-slot:body>
											This will deactivate this Group Config.
											<br />
											<b>Do you want to proceed?</b>
										</template>
										<template v-slot:default="{ showConfirm }">
											<v-icon
												v-bind="attrs"
												v-on="on"
												color="primary"
												size="22"
												class="mx-1"
												@click="showConfirm">
												mdi-delete
											</v-icon>
										</template>
									</ConfirmDialog2>
								</template>
								<span>Delete</span>
							</v-tooltip>

							<v-tooltip
								color="primary darken-2"
								bottom
								:key="`${item.id}-${item.deleted}-restoreBtn`">
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-bind="attrs"
										v-on="on"
										v-if="
											item.deleted != null &&
											_usrFlagsSome({
												key: 'bonus.group_configs.detail',
												val: permissions.DELETE,
											})
										"
										color="primary"
										size="22"
										class="mx-1"
										@click="onRestoreItem(item.id)">
										mdi-restore
									</v-icon>
								</template>
								<span>Restore</span>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
		<GroupConfigEdit
			:item="editedItem"
			:open="addEditDialog"
			@submit="onAddEditSubmit"
			@cancel="onAddSubmitCancel"></GroupConfigEdit>
	</v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { rules as defaultRules } from "../../plugins/rules.ts";
import cardHeading from "../../components/shared/CardHeading.vue";
import GroupConfigEdit from "../../components/forms/GroupConfigEdit.vue";
import { currencyCodes } from "../../constants/currencyCodes";
import ConfirmDialog2 from "../../components/shared/ConfirmDialog2.vue";
import table from "../../mixins/table";
import permissions from "../../mixins/permissions";
import { CONTRIB_TYPES } from "../../constants/contribTypes";

export default {
	components: {
		cardHeading,
		GroupConfigEdit,
		ConfirmDialog2,
	},

	mixins: [permissions, table],
	data() {
		return {
			rowIsClickable: false,
			headers: [
				{ text: "id", value: "id", align: "left" },
				{ text: "Contrib", value: "contrib", align: "center" },
				{ text: "Contrib Type", value: "contrib_type_id", align: "center" },
				{ text: "Currency", value: "currency", align: "center" },
				{
					text: "Deleted",
					value: "deleted",
					align: "center",
					hide: () =>
						!this._usrFlagsSome({
							key: "bonus.group_configs.list",
							val: this.permissions.DELETE,
						}),
				},
				{
					text: "Actions",
					value: "actions",
					align: "center",
					sortable: false,
					hide: () =>
						!this._usrFlagsSome({
							key: "bonus.group_configs.detail",
							val: this.permissions.EDIT | this.permissions.DELETE,
						}),
				},
			],
			deleteDialog: false,
			addEditDialog: false,
			validEdit: false,
			editedItem: {},
			rules: {
				...defaultRules,
				currency: () => true,
			},
			contribTypeDropdown: [
				{ id: 1, contribType: "Percentage" },
				{ id: 2, contribType: "Bet" },
			],
			currencyCodes,
			CONTRIB_TYPES,
		};
	},

	computed: {
		...mapGetters("jackpotGroupConfig2", {
			list: "list",
			pagination: "pagination",
			jackpotDetail: "detail",
		}),
	},

	methods: {
		...mapActions("jackpotGroupConfig2", {
			_loadList: "loadList",
			deleteItem: "delete",
			restoreItem: "unDelete",
			createItem: "create",
			updateItem: "update",
		}),
		...mapMutations("jackpotGroupConfig2", {
			setPagination: "pagination",
			setJackpot: "detail",
		}),
		onAddClick() {
			this.addEditDialog = true;
			this.editedItem = { id: null };
		},
		loadList() {
			return this._loadList(
				!this._usrFlagsSome({
					key: "bonus.group_configs.list",
					val: this.permissions.DELETE,
				})
					? { payload: { deleted: { eq: null } } }
					: undefined
			);
		},

		async onEditItem(item) {
			this.editedItem = { ...item };
			this.addEditDialog = true;
		},
		async onDeleteItem(id) {
			await this.deleteItem(id);
			this._loadTable();
		},
		async onRestoreItem(id) {
			await this.restoreItem(id);
			this._loadTable();
		},
		async onAddSubmitCancel() {
			this.editedItem = {};
			this.addEditDialog = false;
		},
		async onAddEditSubmit() {
			this.editedItem = {};
			this.addEditDialog = false;
			this._loadTable();
		},
	},
};
</script>
