var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "Group_Configs", fluid: "", tag: "section" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "v-card--material pa-3" },
                [
                  _c(
                    "card-heading",
                    { attrs: { color: "secondary", title: "Group configs" } },
                    [
                      _vm._usrFlagsSome({
                        key: "bonus.group_configs.detail",
                        val: _vm.permissions.CREATE,
                      })
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                elevation: "1",
                                fab: "",
                                small: "",
                              },
                              on: { click: _vm.onAddClick },
                            },
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.filteredHeaders,
                      items: _vm.list,
                      options: _vm.options,
                      "server-items-length": _vm.total_records,
                      "item-class": _vm.itemClass,
                      loading: _vm.loading,
                      "footer-props": _vm.footerProps,
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "top",
                          fn: function ({ pagination }) {
                            return [
                              _c(
                                "v-data-footer",
                                _vm._b(
                                  {
                                    staticClass: "anton",
                                    attrs: {
                                      options: _vm.options,
                                      pagination: pagination,
                                    },
                                    on: {
                                      "update:options": function ($event) {
                                        _vm.options = $event
                                      },
                                    },
                                  },
                                  "v-data-footer",
                                  _vm.footerProps,
                                  false
                                )
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.id`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(" " + _vm._s(item.id) + " "),
                              item.deleted &&
                              _vm._usrFlagsSome({
                                key: "bonus.group_configs.list",
                                val: _vm.permissions.DELETE,
                              })
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "ml-3",
                                      attrs: { small: "", color: "error" },
                                    },
                                    [_vm._v(" Deleted ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: `item.contrib_type_id`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("Capitalize")(
                                      item.contrib_type_id === 1
                                        ? _vm.CONTRIB_TYPES.PERCENT
                                        : _vm.CONTRIB_TYPES.BET
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.deleted`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm._f("Date")(item.deleted)) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    color: "primary darken-2",
                                    bottom: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _vm._usrFlagsSome({
                                              key: "bonus.group_configs.detail",
                                              val: _vm.permissions.EDIT,
                                            })
                                              ? _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "mx-1",
                                                        attrs: {
                                                          "nudge-bottom": "1",
                                                          color: "primary",
                                                          size: "22",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onEditItem(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-pencil ")]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Edit")])]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  key: `${item.id}-${item.deleted}-deleteBtn`,
                                  attrs: {
                                    color: "primary darken-2",
                                    bottom: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            item.deleted == null &&
                                            _vm._usrFlagsSome({
                                              key: "bonus.group_configs.detail",
                                              val: _vm.permissions.DELETE,
                                            })
                                              ? _c("ConfirmDialog2", {
                                                  attrs: {
                                                    persistent: "",
                                                    confirmBtnText: "Delete",
                                                    cancelBtnText: "Back",
                                                    confirmBtnColor: "error",
                                                    titleClass: "error",
                                                    cancelBtnColor:
                                                      "kajot-text",
                                                    shouldShow: true,
                                                  },
                                                  on: {
                                                    confirm: function ($event) {
                                                      return _vm.onDeleteItem(
                                                        item.id
                                                      )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "body",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              " This will deactivate this Group Config. "
                                                            ),
                                                            _c("br"),
                                                            _c("b", [
                                                              _vm._v(
                                                                "Do you want to proceed?"
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          showConfirm,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "mx-1",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      size: "22",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        showConfirm,
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " mdi-delete "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Delete")])]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  key: `${item.id}-${item.deleted}-restoreBtn`,
                                  attrs: {
                                    color: "primary darken-2",
                                    bottom: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            item.deleted != null &&
                                            _vm._usrFlagsSome({
                                              key: "bonus.group_configs.detail",
                                              val: _vm.permissions.DELETE,
                                            })
                                              ? _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "mx-1",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "22",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onRestoreItem(
                                                              item.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-restore ")]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Restore")])]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("GroupConfigEdit", {
        attrs: { item: _vm.editedItem, open: _vm.addEditDialog },
        on: { submit: _vm.onAddEditSubmit, cancel: _vm.onAddSubmitCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }